<template>
  <div id="dashboard-mr">
    Dashboard MR
    <br>
    <!-- <iframe id="oldVersion" src="http://192.168.100.152:81/generalClinic" width="100%" height="800px"></iframe> -->
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
